.table-card {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}

.table-section-header {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 15px 0 !important;
  border-bottom: 3px solid #ddd !important;
  margin-right: 20px;
}

.table-header {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 10px !important;
  border-right: none !important;
}

.table-section-gap {
  background-color: transparent !important;
  border: none !important;
  width: 10px;
}

.table-cell {
  text-align: center !important;
  padding: 12px 10px !important;
  border-bottom: 1px solid #ddd !important;
  border-right: none !important;
}

.table-cell:last-child {
  border-right: none !important;
}

.table-row-borderless {
  border-bottom: none !important;
}

.error-cell {
  background-color: #ffcccc !important;
}

.modal {
  position: fixed; /* 화면에 고정 */
  top: 50%; /* 화면 세로 중앙 */
  left: 50%; /* 화면 가로 중앙 */
  transform: translate(-50%, -50%); /* 가운데 정렬 */
  background-color: white; /* 모달 배경색 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  padding: 20px; /* 내부 여백 */
  border-radius: 8px; /* 둥근 모서리 */
  z-index: 1000; /* 가장 위로 표시 */
  width: 500px; /* 모달의 고정된 너비 */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* 반투명한 어두운 배경 */
  z-index: 999;
}